<template>
  <div class='titles'>
    <div class="content">
      <div class='left'>
        <div class="left_text">
          <div class="chs">{{ chs }}</div>
          <div class="en">{{ en }}</div>
        </div>
        <img v-if="!flag.includes('notice') && !flag.includes('detail')" class="more" src="../assets/img/more.png" width="100" height="34">
      </div>

      <div v-if="flag.includes('notice') || flag.includes('detail')" class="right_notice">
        <div class="ikon"></div>
      </div>

      <div v-if="flag.includes('desc')" class="right_desc">
        <div>{{ desc }}</div>
      </div>

      <div v-if="flag.includes('contcat')" class="right_contcat">
        <div>公司地址：{{ detail.address }}</div>
        <div>服务热线：{{ detail.contactTel }}</div>
        <div>联系邮箱：{{ detail.contactEmail }}</div>
        <div>
          <span>企业官方微信：</span>
          <vue-qr ref="qrcode" :text="qrcodeurl" width="150" height="150"></vue-qr>
        </div>
      </div>

      <div v-if="flag.includes('join')" class="right_join">
        <h1>合作申请</h1>
        <h2>加盟热线：{{ tel }}</h2>
        <el-form class="form" ref="formname" :model="form" :rules="rules" label-width="100px" label-position="right">
          <el-form-item label="联系人" prop="contactMan">
            <el-input v-model="form.contactMan" placeholder="请填写联系人"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="contactTel">
            <el-input v-model.number="form.contactTel" type="number" placeholder="请填写联系电话"></el-input>
          </el-form-item>
          <el-form-item label="联系邮箱" prop="contactEmail">
            <el-input v-model="form.contactEmail" placeholder="请填写联系邮箱"></el-input>
          </el-form-item>
          <el-form-item label="留言内容" prop="applyContent">
            <el-input type="textarea" v-model="form.applyContent" :rows="5" placeholder="请填写留言内容" maxlength="200" show-word-limit></el-input>
          </el-form-item>
          <div class="btn">
            <el-button type="primary" @click="onSubmit('formname')">提交</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import vueQr from 'vue-qr'
export default {
  props: {
    flag: {
      type: String,
      required: true,
    },
    chs: {
      type: String,
    },
    en: {
      type: String,
    },
    desc: {
      type: String,
    },
    tel: {
      type: String,
    },
    detail: {
      type: Object,
    },
  },
  components: { vueQr },
  data () {
    return {
      form: {
        contactMan: '',
        contactTel: '',
        contactEmail: '',
        applyContent: '',
      },
      rules: {
        contactMan: [
          { required: true, message: '请填写联系人', trigger: ['blur', 'change'] },
        ],
        contactTel: [
          { required: true, message: '请填写联系电话', trigger: ['blur', 'change'] },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确', trigger: ['blur', 'change'] },
        ],
        contactEmail: [
          { required: true, message: '请填写联系邮箱', trigger: ['blur', 'change'] },
          {
            pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: '邮箱格式不正确',
            trigger: ['blur', 'change'],
          },
        ],
        applyContent: [
          { required: true, message: '请填写留言内容', trigger: ['blur', 'change'] },
          { type: 'string', min: 1, max: 200, message: '留言内容长度为1-200个字符', trigger: ['blur', 'change'] },
        ],
      },
      qrcodeurl: '',
    }
  },
  created () {
    if (this.flag.includes('contcat')) {
      this.qrcodeurl = this.detail.qrCode || ''
    }
  },
  methods: {
    onSubmit (formname) {
      this.$refs[formname].validate(valid => {
        if (valid) {
          this.$emit('submit', this.form);
        } else {
          this.$message.error('请填写完整信息');
          return false;
        }
      });
    },
  },
}
</script>

<style scoped lang='scss'>
.titles {
  height: 1080px;

  .content {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 170px 80px 0;
    background: url(../assets/img/notice_bg.png) no-repeat;
    box-sizing: border-box;
  }

  .left {
    display: flex;
    align-items: center;
    width: 907px;
    height: 375px;
    padding-left: 65px;
    background: url(../assets/img/notice_tit_bg.png) no-repeat;

    .left_text {

      color: #fff;

      .chs {
        font-size: 60px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .en {
        font-size: 30px;
        text-transform: uppercase;
      }
    }

    .more {
      position: absolute;
      left: 80px;
      bottom: 0;
    }
  }

  .right_notice {
    .ikon {
      width: 611px;
      height: 596px;
      background: url(../assets/img/notice_ikon.png) no-repeat;
    }
  }

  .right_desc,
  .right_contcat,
  .right_join {
    width: 700px;
    border-radius: 10px;
    padding: 40px;
    background-color: #fff;
    box-sizing: border-box;
  }

  .right_desc {
    height: 540px;
    overflow-y: auto;

    div {
      color: #171717;
      font-size: 26px;
      line-height: 36px;
      text-indent: 2em;
    }
  }

  .right_contcat {
    height: 440px;
    font-size: 26px;

    div {
      margin-bottom: 24px;
    }

    div:last-child {
      display: flex;
    }
  }

  .right_join {
    height: 600px;

    h1 {
      color: #171717;
      margin-bottom: 20px;
      text-align: center;
    }

    h2 {
      color: #FB7F39;
      font-weight: normal;
      margin-bottom: 45px;
      text-align: center;
    }

    .form {
      width: 540px;
      margin: 0 auto;

      ::v-deep .el-input__inner {
        width: 255px;
      }

      ::v-deep .el-input__inner,
      ::v-deep .el-textarea__inner {
        border-radius: 10px;
      }

      ::v-deep .el-input__count {
        bottom: -10px;
        background-color: transparent;
      }

      .btn {
        text-align: center;

        .el-button {
          width: 120px;
          border-radius: 10px;
        }
      }
    }
  }
}
</style>
